import { gql, useMutation } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import Checkbox from "@/components/common/formElements/checkbox/checkbox";
import useNotificationFromBackend from "@/hooks/subscriptions/useNotificationFromBackend";
import { WorkOrderLineTypes } from "@/types";
import { useOrderStore } from "@/zustand/useOrderStore";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import EachOperationLine from "./EachOperationLine";
import NewOrderLineForm from "./newOrderLineForm";
import OperationLineActions from "./operationLineActions";

import styles from "./index.module.scss";
import textStyles from "@/style/textStyles.module.scss";

type Props = {
  workOrderId: string;
  activeWorkOrderLine: string | undefined;
  setResourcesPanelActive: (workOrderLineId?: string) => void;
  refetch: (any) => void;
};

const START_ORDER_LINE = gql`
  mutation startWorkOrderLines($model: StartWorkOrderLinesModelInput!) {
    startWorkOrderLines(model: $model)
  }
`;

const STOP_ORDER_LINE = gql`
  mutation stopWorkOrderLines($model: StopWorkOrderLinesModelInput!) {
    stopWorkOrderLines(model: $model)
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HandleNotifications: FC<any> = ({ debouncedCallback }) => {
  const notification = useNotificationFromBackend();
  const [lastMessage, setLastMessage] = useState("");
  useEffect(() => {
    if (
      notification?.payload &&
      JSON.stringify(lastMessage) !== JSON.stringify(notification?.payload) &&
      notification?.messageType !== "InvoiceWorkOrderLineUpdated" &&
      notification?.messageType !== "MaintenanceCustomerOrderLineUpdated"
    ) {
      setLastMessage(notification?.payload);
      debouncedCallback();
    }
  }, [
    debouncedCallback,
    lastMessage,
    notification?.messageType,
    notification?.payload,
  ]);

  useEffect(() => {
    return () => {
      debouncedCallback?.cancel();
    };
  }, [debouncedCallback]);
  return null;
};

// Rules to sort operation lines:
// 1. derivedStatus = STARTED
// 2. derivedStatus = STOPPED
// 3. derivedStatus = FINISHED
// 4. derivedStatus = REQUESTED
//     1. Operation lines with assigned ***Work Center*** + ***Operator***
//     2. Operation lines with assigned ***Work Center***
//     3. Operation lines with assigned ***Operator***
// - All remaining operation lines

// Define the order of statuses
const statusOrder = {
  STARTED: 1,
  STOPPED: 2,
  FINISHED: 3,
  REQUESTED: 4,
};

// Custom sorting function
export function customOrderLinesSort(a, b) {
  // Give low priority (number 10) if the status is not in our priority list (statusOrder array)
  const statusComparison =
    (statusOrder[a.derivedStatus] ?? 10) - (statusOrder[b.derivedStatus] ?? 10);

  // If statuses are different, return the comparison result
  if (statusComparison !== 0) {
    return statusComparison;
  }

  const requiA = a.assignment;
  const requiB = b.assignment;
  const countA = (requiA?.workCenter ? 1 : 0) + (requiA?.operator ? 1 : 0);
  const countB = (requiB?.workCenter ? 1 : 0) + (requiB?.operator ? 1 : 0);

  if (countA > countB) {
    // Case 1: Operation lines with assigned Work Center + Operator
    return -1;
  }
  return 1;
}

const OperationLinesList: FC<Props> = ({
  workOrderId,
  activeWorkOrderLine,
  setResourcesPanelActive: setResourcesPanelActiveWithWOID,
  refetch,
}) => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const userData = useUserData();

  const [startWorkOrderLines] = useMutation(START_ORDER_LINE);
  const [stopWorkOrderLines] = useMutation(STOP_ORDER_LINE);

  const [isSelectAll, setSelectAll] = useState(false);
  const [orderLines, setOrderLines] = useState<WorkOrderLineTypes[]>([]);
  const [assignedTeam, setAssignedTeam] = useState<any>();

  const broadcastWO = useOrderStore((state) => state.broadcastWO);

  useMemo(() => {
    setOrderLines(broadcastWO?.workOrderById?.lines);
    setAssignedTeam(broadcastWO?.workOrderById?.assignedTeam);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(broadcastWO)]);

  const [selectedIds, setSelectedIds] = useState<
    {
      id: string;
      operatorId: string;
      laborChargeCode: string;
    }[]
  >([]);

  useEffect(() => {
    if (isEmpty(selectedIds)) {
      setSelectAll(false);
    }
  }, [selectedIds]);

  const refetchWorkOrder = useCallback(() => {
    refetch({ workOrderId });
    // Bug fix. it seems we are a bit too early to refetch the data

    // OLD NOTES: (I don't know what this has to do with anything)
    // (status changed from 40 to 36 if we see the WS event (both are considered "planned",
    // but just a bit after we receive the last WS event, it changes to status 60, that is "started"))
    // So the solution: is to refetch after some time, to warranty that this edge case doesn't bite us:
    setTimeout(() => {
      refetch({ workOrderId });
    }, 3000);
  }, [refetch, workOrderId]);

  const debouncedRefetchWorkOrder = useMemo(
    () => debounce(refetchWorkOrder, 50),
    [refetchWorkOrder],
  );

  const selectAllOrderLines = () => {
    if (!isEmpty(orderLines) && !isSelectAll) {
      const elements = orderLines.filter(
        (el: WorkOrderLineTypes) =>
          el.assignment?.operator && el.assignment?.capabilityTypeNumber,
      );
      setSelectedIds(
        elements.map((e: WorkOrderLineTypes) => {
          return {
            id: e.id,
            operatorId: e.assignment?.operator?.id || "",
            laborChargeCode: e.assignment?.capabilityTypeNumber?.toString(),
          };
        }),
      );
    }
    if (isSelectAll) {
      setSelectedIds([]);
    }
    setSelectAll(!isSelectAll);
  };

  const handleStartWorkOrderLines = (date: string) => {
    if (!isEmpty(selectedIds)) {
      const model = {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        workOrderLines: selectedIds.map((el) => {
          return {
            operatorId: el.operatorId,
            date,
            workOrderLineId: el.id,
          };
        }),
      };
      startWorkOrderLines({
        variables: {
          model,
        },
      });
      setSelectedIds([]);
    }
  };

  const setResourcesPanelActive = (workOrderLineId: string | undefined) => {
    setResourcesPanelActiveWithWOID(
      `${workOrderId ?? ""}|${workOrderLineId ?? ""}`,
    );
  };

  const handleStopWorkOrderLines = (date: string) => {
    if (!isEmpty(selectedIds)) {
      const model = {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        workOrderLines: selectedIds.map((el) => {
          return {
            operatorId: el.operatorId,
            date,
            workOrderLineId: el.id,
            laborChargeCode: el.laborChargeCode,
          };
        }),
      };
      stopWorkOrderLines({
        variables: {
          model,
        },
      });
      setSelectedIds([]);
    }
  };

  const handleDeleteWorkOrderLine = (id: string) => {
    const newArr = [...orderLines];
    if (newArr.find((el) => id === el.id)) {
      newArr.splice(
        newArr.findIndex((i) => i.id === id),
        1,
      );
      setOrderLines(newArr);
    }
    setResourcesPanelActiveWithWOID("");
  };

  const sortedOrderLines = useMemo(() => {
    // Check if orderLines is iterable
    if (!Array.isArray(orderLines)) {
      // Return an empty array or handle the case as appropriate
      return [];
    }
    // Sort the operation lines using the custom comparator
    // Create a new array from orderLines because sometimes could complain about "read-only" array
    return [...orderLines].sort(customOrderLinesSort);
  }, [orderLines]);

  let adhocTeam = false;
  let count = "";

  const started = orderLines?.filter(
    (orderLine: any) => orderLine?.derivedStatus === "STARTED",
  ).length;

  let allAssigned = 0;
  orderLines?.forEach((orderLine: any) => {
    if (orderLine?.assignment?.operator?.id) {
      allAssigned += 1;
      adhocTeam = true;
    }
  });

  count = `${started || 0}/${allAssigned || 0}`;

  let teamInfo = "Unassigned";
  if (adhocTeam && !assignedTeam) {
    teamInfo = `Ad Hoc Team${count ? ` (${count})` : ""}`;
  } else if (assignedTeam?.name) {
    teamInfo = `${assignedTeam?.name || ""}${count ? ` (${count})` : ""}`;
  }

  return (
    <div className={styles.OperationLinesWrapper}>
      <h5 className={textStyles.secondaryText}>
        TEAM:{" "}
        <span className={textStyles.secondaryText}>
          {orderLines && <span>{teamInfo}</span>}
          {!orderLines && "..."}
        </span>
      </h5>

      <h5 className={textStyles.secondaryText}>WORK ORDER OPERATION LINES:</h5>
      <HandleNotifications debouncedCallback={debouncedRefetchWorkOrder} />
      <OperationLineActions
        handleStartWorkOrderLine={handleStartWorkOrderLines}
        handleStopWorkOrderLine={handleStopWorkOrderLines}
      />
      <div className={styles.selectAllBlock}>
        <Checkbox
          checked={isSelectAll}
          onChange={selectAllOrderLines}
          label="Select All"
        />
      </div>
      <div style={{ position: "relative" }}>
        {!broadcastWO && <LinearProgress />}
      </div>
      {!isEmpty(sortedOrderLines) &&
        sortedOrderLines.map((ol: WorkOrderLineTypes) => (
          <EachOperationLine
            key={ol?.id}
            data={ol}
            setResourcesPanelActive={setResourcesPanelActive}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            removeWorkOrderLine={handleDeleteWorkOrderLine}
            active={activeWorkOrderLine === ol.id}
          />
        ))}
      <NewOrderLineForm workOrderId={workOrderId} />
    </div>
  );
};
export default memo(OperationLinesList);
