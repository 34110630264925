/* eslint-disable no-nested-ternary */
import { gql, useMutation, useQuery } from "@apollo/client";
import moment, { Moment } from "moment-timezone";
import React, { FC, memo, useState } from "react";

import DateAndTimeField from "@/components/common/formElements/dateAndTimeField";
import InputText from "@/components/common/formElements/inputText";
import DropZone from "@/components/timeline/sidePanel/dropzone";
import { validateStartBeforeStop } from "@/helpers/dateTimeValidator";
import {
  OrderStatusColors,
  OrderStatusEnum,
} from "@/helpers/OrderStatusColors";
import warningIcon from "@/style/img/warning.svg";
import { OrderUpdatedEventParams, WorkOrder as WorkOrderTypes } from "@/types";
import { useInProgressStore } from "@/zustand/useInProgressStore";
import {
  mutateWorkOrderOrderedBy,
  mutateWorkOrderPONumber,
  useOrderStore,
} from "@/zustand/useOrderStore";
import { useSettingsStore, useUserData } from "@/zustand/useSettingsStore";

import { RELEASE_WO } from "../allocations/workOrderActionsPopUp";
import InvoicePanel from "./invoicePanel/invoicePanel";
import {
  CREATE_WORK_ORDER_NOTE,
  GET_WORK_ORDER_NOTES,
  UPDATE_WORK_ORDER_NOTE,
} from "./notes/notesQueriesAndMutations";
import OrderNotes, {
  NotesMutationParams,
  NotesQueryParams,
  NotesQueryPayloadIdentifier,
} from "./notes/orderNotes";
import MaterialsList from "./operationLines/MaterialsList";
import OperationLinesList from "./operationLines/OperationLinesList";

import styles from "./sidePanel.module.scss";
import formStyles from "@/components/common/formElements/customFormStyles.module.scss";
import textStyles from "@/style/textStyles.module.scss";

const FULL_INFO = gql`
  query getOrderLinesById($workOrderId: String!) {
    workOrderById(workOrderId: $workOrderId) {
      id
      status
      derivedStatus
      assignedTeam {
        id
        name
      }
      lines {
        status
        derivedStatus
        workOrderId
        capability
        id
        description
        operationNumber
        plannedStartDate
        plannedStopDate
        startDate
        stopDate
        assignment {
          description
          workOrderLineNumber
          workCenterType
          capabilityType
          capabilityTypeNumber
          operationElementId
          operator {
            id
            firstName
            middleName
            lastName
          }
          workCenter {
            id
            name
            description
          }
          workOrderType
        }
        timestampOperations {
          id
          startDate
          stopDate
          costingTypeId
          disturbance {
            id
            name
            description
          }
          laborChargeCodeId
          usedLabor
          status
        }
        # capabilityRequirements {
        #   id
        #   employeeNumber
        #   description
        #   capabilityType
        #   operator {
        #     firstName
        #     middleName
        #     lastName
        #     id
        #   }
        #   operationElement
        #   workcenter {
        #     id
        #     name
        #     description
        #   }
        # }
      }

      workOrderMaterials {
        id
        itemNumber
        itemType
        itemName
        quantity
        materialStatus
        sequenceNumber
        unitsOfMeasurement
        workOrderLineNumber
        workOrderNumber
      }
      workOrderNotes {
        description
        entryDate
        id
        lastModified
        noteType
        text
        textIdentity
        modifiedBy
        specifyDescription
      }
      attachments {
        id
        # attachedToId
        # attachmentType
        # displayName
        filename
        # facilityId
        # isSynchronized
        # mimeType
        # modifiedBy
        # modifiedDate
        # size
        # uploadDate
        # uploadedBy
        # version
      }
    }
  }
`;

type Props = {
  wo: WorkOrderTypes;
  activeWorkOrderLine: string | undefined;
  setResourcesPanelActive: (workOrderLineId?: string) => void;
  setUpdatedWorkOrder: (data: Partial<OrderUpdatedEventParams> | null) => void;
  showDivider?: boolean;
  toggleInvoicePanel: any;
  onClickAway?: any;
  isInvoicePanelActive: boolean;
};

const WorkOrderSidePanel: FC<Props> = ({
  wo,
  activeWorkOrderLine,
  setResourcesPanelActive,
  setUpdatedWorkOrder,
  showDivider = false,
  toggleInvoicePanel,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClickAway = () => {},
  isInvoicePanelActive,
}) => {
  // Notice: We use the props' start and stop date as initial value for this local useState.
  // BUT if new updates come through props (other users doing changes to this vessel), we don't refresh them.
  const [startDate, setStartDate] = useState<Moment | null>(
    wo.plannedStartDate ? moment(wo.plannedStartDate) : null,
  );
  const [stopDate, setStopDate] = useState<Moment | null>(
    wo.plannedStopDate ? moment(wo.plannedStopDate) : null,
  );
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const [releaseWorkOrder] = useMutation(RELEASE_WO);
  const [poNumber, setPoNumber] = useState<string>(wo?.poNumber);
  const [orderedBy, setOrderedBy] = useState<string>(wo?.orderedBy);
  const [workOrderExtraInfo, setWorkOrderExtraInfo] = useState<any>();
  const allTimestampsStopped =
    workOrderExtraInfo?.workOrderById?.lines?.length > 0 &&
    (
      workOrderExtraInfo?.workOrderById?.lines?.filter((ol) => {
        // console.log(
        //   ol.timestampOperations?.some(
        //     (t) => t?.stopDate === undefined || t?.stopDate === null
        //   )
        // );
        return !!ol.timestampOperations?.some(
          (t) => t?.stopDate === undefined || t?.stopDate === null,
        );
      }) || []
    ).length === 0;

  const [showWarning, setShowWarning] = useState<boolean>(false);
  const triggerBroadcastWO = useOrderStore((state) => state.triggerBroadcastWO);
  const poChangeInProgress = useInProgressStore(
    (state) => state.poChangeInProgress,
  );

  const { loading, refetch } = useQuery(FULL_INFO, {
    variables: { workOrderId: wo.id },
    onCompleted: (res) => {
      triggerBroadcastWO(res);
      setWorkOrderExtraInfo(res);
    },
  });

  const handleSetPoNumber = (newValue: string) => {
    setPoNumber(newValue);
    setUpdatedWorkOrder({ id: wo.id, poNumber: newValue });
  };

  const savePoNumber = (e: any) => {
    const newPoNumber = e.target?.value ?? "";
    mutateWorkOrderPONumber(
      {
        id: wo?.id,
        poNumber: newPoNumber,
        // Notice: This is different value than sidePanelOrder?.orderNumber
        orderNumber: wo?.mCO?.referenceOrderNumber,
        orderLineNumber: wo?.mCO?.referenceOrderLine,
      },
      wo?.normalizedOrderType === "subwo",
    );
  };
  const handleSetOrderedBy = (newValue: string) => {
    setOrderedBy(newValue);
    setUpdatedWorkOrder({ id: wo.id, orderedBy: newValue });
  };

  const saveOrderedBy = (e: any) => {
    const newOrderedBy = e.target?.value ?? "";
    mutateWorkOrderOrderedBy(
      {
        id: wo?.id,
        orderedBy: newOrderedBy,
        // Notice: This is different value than sidePanelOrder?.orderNumber
        maintenanceCustomerOrderNumber: wo?.mCO?.referenceOrderNumber,
        maintenanceCustomerOrderLineNumber: wo?.mCO?.referenceOrderLine,
      },
      wo?.normalizedOrderType === "subwo",
    );
  };
  const handleSetStartDate = (newValue: Moment | null) => {
    setStartDate(newValue);
    setUpdatedWorkOrder({
      id: wo.id,
      plannedStartDate: newValue?.toISOString() || "",
    });
  };

  const handleSetStopDate = (newValue: Moment | null) => {
    setStopDate(newValue);

    setUpdatedWorkOrder({
      id: wo.id,
      plannedStopDate: newValue?.toISOString() || "",
    });
  };

  const handleRelease = async () => {
    await releaseWorkOrder({
      variables: {
        facilityId: selectedFacility,
        issuedBy: userData.mail,
        workOrderId: wo.id,
      },
    });
  };

  // .specifyDescription is unique. [0] is fine.workOrderById.workOrderNotes
  const specifyDescription =
    workOrderExtraInfo?.workOrderById?.workOrderNotes?.filter(
      (note) => note?.specifyDescription,
    )?.[0]?.specifyDescription;

  const isStartBeforeStop = validateStartBeforeStop(startDate, stopDate);
  return (
    <>
      {showDivider && (
        <hr style={{ marginTop: "25px", backgroundColor: "#E0E0E0" }} />
      )}
      <div className={styles.infoBlock}>
        <div className={styles.fieldNames}>
          <span className={textStyles.secondaryText}>Work order:</span>
          <span className={textStyles.secondaryText}>Customer:</span>
          <span className={textStyles.secondaryText}>Order Number:</span>
          <span className={textStyles.secondaryText}>MCO:</span>
          <span className={textStyles.secondaryText}>Purchase order:</span>
          <span className={textStyles.secondaryText}>Ordered by:</span>
          <span className={textStyles.secondaryText}>Status:</span>
          <span className={`${textStyles.secondaryText} ${styles.taller}`}>
            Planned start:
          </span>
          <span className={`${textStyles.secondaryText} ${styles.taller}`}>
            Planned end:
          </span>
          <span className={textStyles.secondaryText}>Actual start:</span>
          <span className={textStyles.secondaryText}>Work Order Closed:</span>
          <span className={textStyles.secondaryText}>Description:</span>
        </div>
        <div className={styles.fieldInfo}>
          <span className={textStyles.primaryText}>
            {wo.description || "N/A"}
          </span>
          <span className={textStyles.primaryText}>
            {wo.customerDisplayName || "N/A"}
          </span>
          <span className={textStyles.primaryText}>{wo.orderNumber}</span>
          <span
            className={textStyles.primaryText}
          >{`${wo?.mCO?.referenceOrderNumber} / ${wo?.mCO?.referenceOrderLine}`}</span>
          <span
            className={`${textStyles.primaryText} ${formStyles.compactInput}`}
          >
            <InputText
              value={poNumber}
              setValue={handleSetPoNumber}
              onBlur={savePoNumber}
              placeholder="N/A"
              error={false}
              errorMessage=""
            />
            {poChangeInProgress.some(
              (o) =>
                o.id ===
                `${wo?.mCO?.referenceOrderNumber}-${wo?.mCO?.referenceOrderLine}`,
            )
              ? " Pending"
              : ""}
          </span>
          <span
            className={`${textStyles.primaryText} ${formStyles.compactInput}`}
          >
            <InputText
              value={orderedBy}
              setValue={handleSetOrderedBy}
              onBlur={saveOrderedBy}
              placeholder="N/A"
              error={false}
              errorMessage=""
            />
          </span>
          <div className={`${styles.status}`}>
            <aside
              className="status-indicator"
              style={{
                background: `${OrderStatusColors.getColor(
                  wo.derivedStatus as OrderStatusEnum,
                )}`,
              }}
            />
            <span className={`${textStyles.primaryText} status-label`}>
              {OrderStatusColors.getName(wo.derivedStatus as OrderStatusEnum)}{" "}
              <span style={{ color: "#AAA" }}>{wo.status}</span>
            </span>
          </div>
          <DateAndTimeField
            className={styles.nsgDatetimePicker}
            dateValue={startDate}
            handleChange={handleSetStartDate}
            errorJSX={isStartBeforeStop ? undefined : ""}
          />
          <DateAndTimeField
            className={styles.nsgDatetimePicker}
            dateValue={stopDate}
            handleChange={handleSetStopDate}
            errorJSX={isStartBeforeStop ? undefined : ""}
          />
          <span className={textStyles.primaryText}>
            {wo.startDate
              ? moment(wo.startDate).format("DD.MM.YYYY, HH:mm")
              : "N/A"}
          </span>
          <span className={textStyles.primaryText}>
            {wo.stopDate
              ? moment(wo.stopDate).format("DD.MM.YYYY, HH:mm")
              : "N/A"}
          </span>
          {!specifyDescription ? (
            loading ? (
              <span className={textStyles.primaryText}>loading</span>
            ) : (
              <span className={textStyles.primaryText}>N/A</span>
            )
          ) : null}
        </div>
      </div>
      {!isStartBeforeStop && (
        <p className="error-message">
          Check that the start date is later than the end date.
          <br /> Give it another try!
        </p>
      )}
      {specifyDescription && (
        <div
          className={`${textStyles.primaryText} ${styles.specifyDescription}`}
        >
          {specifyDescription}
        </div>
      )}
      {parseInt(wo.status, 10) === 10 && (
        <button
          className={`${textStyles.primaryText} ${styles.fullWidthButton} `}
          style={{
            backgroundColor:
              parseInt(wo.status, 10) === 10 ? "#94D4DB" : "#DEDEDE",
            color: "black",
            borderColor: parseInt(wo.status, 10) === 10 ? "#94D4DB" : "#DEDEDE",
          }}
          type="button"
          disabled={parseInt(wo.status, 10) !== 10}
          onClick={(e) => {
            if (parseInt(wo.status, 10) === 10) {
              // Change the button text to "..." and disable it
              e.target.innerText = "Releasing ...";
              e.target.disabled = true;

              // Call your handleRelease function
              handleRelease();
            }
          }}
        >
          Release Work Order
        </button>
      )}
      {workOrderExtraInfo?.workOrderById?.lines?.length > 0 &&
        parseInt(wo.status, 10) >= 70 &&
        parseInt(wo.status, 10) < 96 && (
          <>
            <button
              className={`${textStyles.primaryText} ${styles.fullWidthButton} `}
              style={{
                backgroundColor:
                  parseInt(wo.status, 10) === 95
                    ? "#DEDEDE"
                    : allTimestampsStopped
                      ? "#94D4DB"
                      : "#F6E2E1",
                color: "black",
                borderColor:
                  parseInt(wo.status, 10) === 95
                    ? "#DEDEDE"
                    : allTimestampsStopped
                      ? "#94D4DB"
                      : "#F6E2E1",
              }}
              type="button"
              onClick={() => {
                if (allTimestampsStopped) {
                  toggleInvoicePanel();
                } else {
                  setShowWarning(true);
                }
              }}
              title="review and invoice"
            >
              {parseInt(wo.status, 10) === 95
                ? "Invoiced"
                : `Review and invoice`}
            </button>
            {!allTimestampsStopped && showWarning ? (
              <div>
                <img
                  src={warningIcon}
                  alt="Warning"
                  style={{
                    margin: "4px 10px 0px 3px",
                    color: "black",
                    borderColor: allTimestampsStopped ? "#00ADBB" : "#E6D2D1",
                  }}
                />
                <span
                  className={textStyles.secondaryText}
                  style={{ verticalAlign: "text-top", lineHeight: "1.1em" }}
                >
                  All timestamps must have STOP time.
                </span>
              </div>
            ) : null}
          </>
        )}
      <OperationLinesList
        workOrderId={wo.id}
        activeWorkOrderLine={activeWorkOrderLine}
        setResourcesPanelActive={setResourcesPanelActive}
        refetch={refetch}
      />
      {workOrderExtraInfo !== undefined && (
        <MaterialsList
          workOrderId={wo.id}
          initialItems={workOrderExtraInfo?.workOrderById?.workOrderMaterials}
        />
      )}
      <OrderNotes
        title="WORK ORDER Notes"
        noteParentId={wo.id}
        mutationParams={
          {
            create: CREATE_WORK_ORDER_NOTE,
            update: UPDATE_WORK_ORDER_NOTE,
            createParam: "workOrderId",
            updateParam: "orderNoteId",
          } as NotesMutationParams
        }
        queryParams={
          {
            query: GET_WORK_ORDER_NOTES,
            param: "workOrderId",
            payloadIdentifier: {
              identifier1: "workOrderById",
              identifier2: "workOrderNotes",
            } as NotesQueryPayloadIdentifier,
          } as NotesQueryParams
        }
      />
      {workOrderExtraInfo !== undefined && (
        <div className={styles.attachments}>
          <h3 className={`${textStyles.secondaryText} ${styles.sectionHeader}`}>
            Attachments:
          </h3>
          <DropZone
            attachments={workOrderExtraInfo?.workOrderById?.attachments}
            orderType="workorder"
            rentLine={wo?.mCO?.referenceOrderLine}
            rentLineNumber={wo?.mCO?.referenceOrderNumber}
            refetch={refetch}
          />
        </div>
      )}
      {isInvoicePanelActive && (
        <InvoicePanel
          wo={wo}
          extra={workOrderExtraInfo?.workOrderById?.lines}
          onClickAway={onClickAway}
        />
      )}
    </>
  );
};
export default memo(WorkOrderSidePanel);
